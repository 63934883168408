.App {
  text-align: center;
  background-image: url("capeView.JPG");
  background-size: cover;
  background-position: center;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: white;
}

.icon {
  padding: 10px;
  size: 200px;
}

.navbar-light .navbar-brand {
  color: #fff;
}

.navbar-light .navbar-nav .nav-link {
  border-radius: 25px;
  border: 2px solid #fff;
  color: #fff;
}

.navbar-light:hover .navbar-brand:hover {
  color: #fff;
}

.navbar-light:hover .navbar-nav:hover .nav-link:hover {
  color: #fff;
}

.bg-dark {
  background-color: transparent !important;
}

.navbar-brand {
  color: #fff;
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
  font-size: 20pt;
}

.contact-details {
  text-align: left;
  margin-top: 5px;
  font-size: 20px;
  width: 300px;
  text-align: left;
}

.contact {
  margin-top: 70px;
  font-size: 25px;
  width: 300px;
  text-align: left;
}

.info {
  text-align: left;
}
